import axios from 'axios';
export var data_findByMacid
// export const server_url = "http://localhost:8080"
// export const server_url = "http://192.168.29.212:8080" //Local
export const google_map_api_key = ""
// export const server_url = "https://api.iotinfra.link" //Maruti
// export const server_url = "https://gw-manager.evolveikonnect.com" //Aliter Solutions
export const server_url = "http://gw-manager.evolveikonnect.com" //Aliter Solutions
// export const server_url = "http://localhost:8081" //Aliter Solutions Local

//  

export async function update_findByMacid(url) {
  axios
    .get(
      `${url}`
    )
    .then((response) => {
      // console.log('DEBUG zone response', response);
      // console.log(response.data[0]);
      // data_findByMacid = response
      data_findByMacid = response
      // console.log(data_findByMacid.data)
      return data_findByMacid;
      // setDevice(response.data[0]);
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
}